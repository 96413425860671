import React                              from 'react'
import {tail, filter, flip, pipe, concat} from 'ramda'
import styled                             from '@emotion/styled'
import {css}                              from '@emotion/core'
import {Blanquita}                        from '../../components/estilos/estilos-de-articulo'
import ToggleButton                       from '@material-ui/lab/ToggleButton'
import Paper                              from '@material-ui/core/Paper'
import Collapse                           from '@material-ui/core/Collapse'
import ToggleButtonGroup                  from '@material-ui/lab/ToggleButtonGroup'
import {conseguirMensajesPorTipoYEstado}  from '../../utiles/da'
import {efectuarModificaciones}           from '../../utiles/da'
import {estados, tipos}                   from '../../utiles/constantes'
import EspaciadorVertical                 from '../../components/espaciador-vertical'
import {CAMBIO_DE_ESTADO_DEL_MENSAJE}     from '../../utiles/constantes'
import {Button}                           from '@material-ui/core'
import Conversacion                       from '../../components/conversacion'
import EmojiFoodBeverageOutlinedIcon      from '@material-ui/icons/EmojiFoodBeverageOutlined'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import HowToRegOutlinedIcon               from '@material-ui/icons/HowToRegOutlined'
import ContactSupportOutlinedIcon         from '@material-ui/icons/ContactSupportOutlined'
import {faComment}                        from '@fortawesome/free-regular-svg-icons'


// tipo   enum ('asesoramiento','contacto','subscripcion','comentario','respuesta')
// estado enum('pendiente','publicado','spam','borrado','rechazado')

const csspendiente  = css` padding: 4px; color: white; background-color: #ef6c00; ` // orange 800
const csspublicado  = css` padding: 4px; color: white; background-color: #388e3c; ` // gree 700
const cssspam       = css` padding: 4px; color: white; background-color: #5d4037; ` // brouwn 700
const cssborrado    = css` padding: 4px; color: white; background-color: #616161; text-decoration: line-through; ` // grey 700
const cssrechazado  = css` padding: 4px; color: white; background-color: #c62828; ` // red 800
const cssnose       = css` padding: 4px; color: white; background-color: white; `

const cssestado = estado =>
      estado === 'pendiente' ? csspendiente
    : estado === 'publicado' ? csspublicado
    : estado === 'spam'      ? cssspam
    : estado === 'borrado'   ? cssborrado
    : estado === 'rechazado' ? cssrechazado
    : estado === 'publicado' ? csspublicado
    : cssnose

const Contenido = styled.div`
    background-color: ${props => (props.seleccionado ? '#16455a' : 'white')};
    color: ${props => (props.seleccionado ? 'white' : 'black')};
    `
const TipoYEstado                   = styled.div(({estado}) => ({...cssestado(estado)}))
const TipoDelMensaje                = styled.div` text-align: center; text-transform: uppercase; `
const IdDelMensaje                  = styled.div` text-align: center; font-size: 22px; font-weight: 400; `
const EstadoDelMensaje              = styled.div` text-align: center; `
const StatusLine                    = styled.div` display: flex; padding-bottom: 8px; padding: 8px 1.5rem; border-radius: 2px; `
const ParteIzquierdaDeLaStatusLine  = styled.div` width: 50%; text-align: left; display: flex; `
const ParteDerechaDeLaStatusLine    = styled.div` width: 50%; text-align: right; display: flex; justify-content: flex-end; `
const ActionsLine                   = styled.div` display: flex; padding-bottom: 8px; padding: 0 1.5rem; border-radius: 2px; min-height: 48px; `
const ParteIzquierdaDeLaActionsLine = styled.div` width: 50%; text-align: left; margin-top: auto; margin-bottom: auto; display: flex; `
const ParteDerechaDeLaActionsLine   = styled.div` width: 50%; text-align: right; display: flex; justify-content: flex-end; `
const Sobrenombre                   = styled.div` font-weight: bold; font-size: 1.2rem; margin-right: 4px; `
const Correo                        = styled.div` font-weight: bold; font-size: 1.2rem; margin-right: 4px; opacity: 0.5; `
const Fecha                         = styled.div` margin-top: auto; margin-bottom: auto; `

const cssmensaje                    = css` display:grid; grid-template-columns 120px auto; font-size:12px; `
const csstexto                      = css` padding-left: 20px; padding-top: 4px; padding-bottom: 4px; `

const Texto = ({texto}) => (
  <div css={csstexto}>
    <div>{texto}</div>
  </div>
)

const EnvoltorioDelMensaje = ({children, handleClick}) => (
  <div
    css       = {cssmensaje}
    role      = "complementary"
    onClick   = {handleClick}
    onKeyDown = {handleClick}
  >
    {children}
  </div>
)

const ContenidoDeLaIzquierdaDeLaActionsLine = ({mensaje}) =>
    mensaje.tipo === 'asesoramiento' ? <div><b>Asunto:</b> {mensaje.asunto}</div>
  : mensaje.tipo === 'comentario'    ? <div><b>{`En el artículo "${mensaje.articulo}"`}</b></div>
  :                                    <span></span>

const ContenidoDeLaDerechaDeLaActionsLine = ({
  mensaje,
  seleccionado,
  handleChange,
}) => {
  const handleClickVerConversacion = event => {
    event.stopPropagation()
    event.preventDefault()
    handleChange()
  }
  return mensaje.tipo === 'comentario' ? (
    <Button
      color={seleccionado ? 'inherit' : 'inherit'}
      onClick={handleClickVerConversacion}
    >
      Ver conversación
    </Button>
  ) : (
    <span></span>
  )
}
const csssuperficie = css`
  margin: 1rem;
`
const SuperficieDelMensaje = ({children}) => (
  <div css={csssuperficie}>
    <Paper>{children}</Paper>
  </div>
)
const EnvoltorioDeLaConversacion = styled.div`
padding-bottom: 1rem;
`
const cssiconodelestado = css`
  display:flex;
  justify-content: center;
  aling-items: center;
  & .iconico {
    font-size: 40px;
  }
`
const IconoDelTipo = ({tipo}) => <div css={cssiconodelestado}>
  { tipo === 'asesoramiento'  ? <EmojiFoodBeverageOutlinedIcon className="iconico" />
  : tipo === 'subscripcion'   ? <HowToRegOutlinedIcon className="iconico" />
  : tipo === 'contacto'       ? <ContactSupportOutlinedIcon className="iconico" />
  : tipo === 'comentario'     ? <FontAwesomeIcon icon={faComment} className="iconico" />
  : <EmojiFoodBeverageOutlinedIcon className="iconico" />}
</div>
const Mensaje = ({mensaje, seleccionado, handleClick}) => {
  const {
    id,
    tipo,
    estado,
    estampado: fecha,
    texto,
    autor: {sobrenombre, correo},
  } = mensaje
  const [checked, setChecked] = React.useState(false)
  const handleChange = () => {
    setChecked(prev => !prev)
  }

  return (
    <SuperficieDelMensaje>
      <EnvoltorioDelMensaje seleccionado={seleccionado} handleClick={handleClick} >
        <TipoYEstado estado={estado}>
          <IconoDelTipo tipo={tipo} />
          <IdDelMensaje>{id}</IdDelMensaje>
          <TipoDelMensaje>{tipo}</TipoDelMensaje>
          <EstadoDelMensaje>{estado}</EstadoDelMensaje>
        </TipoYEstado>
        <Contenido seleccionado={seleccionado}>
          <StatusLine seleccionado={seleccionado}>
            <ParteIzquierdaDeLaStatusLine>
              <Sobrenombre>{`@${sobrenombre.replace(/@/g, '')}`}</Sobrenombre>
              <Correo>{correo}</Correo>
            </ParteIzquierdaDeLaStatusLine>
            <ParteDerechaDeLaStatusLine>
              <Fecha>{fecha}</Fecha>
            </ParteDerechaDeLaStatusLine>
          </StatusLine>
          <Texto texto={texto}></Texto>
          {mensaje.tipo === 'comentario' ? (
            <ActionsLine seleccionado={seleccionado}>
              <ParteIzquierdaDeLaActionsLine>
                <ContenidoDeLaIzquierdaDeLaActionsLine mensaje={mensaje} />
              </ParteIzquierdaDeLaActionsLine>
              <ParteDerechaDeLaActionsLine>
                <ContenidoDeLaDerechaDeLaActionsLine
                  mensaje={mensaje}
                  seleccionado={seleccionado}
                  handleChange={handleChange}
                />
              </ParteDerechaDeLaActionsLine>
            </ActionsLine>
          ) : (
            <ActionsLine seleccionado={seleccionado}>
              <ContenidoDeLaIzquierdaDeLaActionsLine mensaje={mensaje} />
            </ActionsLine>
          )}
          <Collapse in={checked} mountOnEnter unmountOnExit>
            <EnvoltorioDeLaConversacion>
              <Conversacion id={id} handleChange={handleChange} />
            </EnvoltorioDeLaConversacion>
          </Collapse>
        </Contenido>
      </EnvoltorioDelMensaje>
    </SuperficieDelMensaje>
  )
}

const EnvoltorioDeLaTabla = styled.div`
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 60px;
  padding-top: 0;
  display: grid;
  background-color: #f0f0f0;
  grid-template-columns: 200px auto 200px;
`
const SidebarIzquierdo = styled.div`
  height: 100vh;
  max-width: 400px;
  text-align: center;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 1px solid transparent;
  overflow-y: scroll;
  padding: 16px 4px 0 4px;
`
const SidebarDerecho = styled.div`
  height: 100vh;
  padding: 16px 8px 0 8px;
  max-width: 400px;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
`
const LaTabla = styled.div`
  height: 100vh;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  overflow-y: scroll;
`
const Estado      = styled.div` text-transform: capitalize; `
const Tipo        = styled.div` text-transform: capitalize; `
const Accion      = styled.div` text-transform: capitalize; `
const cssestados  = css` padding: 1rem; width: 80%; margin: 0 auto; text-align: center; `
const csstipos    = css` padding: 1rem; width: 80%; margin: 0 auto; text-align: center; `
const cssacciones = css` padding: 1rem; width: 80%; margin: 0 auto; text-align: center; `

const EnvoltorioDeBotonSolitario = styled.div` margin: 8px auto 0 auto; width: 90%; text-align: center; `

const SelectoresDeEstado = ({estadoActual, handleChangeEstado}) => (
  <ToggleButtonGroup
    orientation = "vertical"
    value       = {estadoActual}
    exclusive
    onChange    = {handleChangeEstado}
    css         = {cssestados}
  >
    {estados.map((estado, indice) => (
      <ToggleButton
        size       = "small"
        value      = {estado.clave}
        aria-label = {estado.clave}
        key        = {indice}
      >
        <Estado>{`${estado.nombre}s`}</Estado>
      </ToggleButton>
    ))}
  </ToggleButtonGroup>
)

const csstitulotabla = css`
  text-align: center;
  font-size: 26px;
  height: 32px;
  background-color: #16455a;
  color: white;
  font-weight: 300;
  padding: 4px;
  margin: 16px 12px 0 12px;
`
const TituloDeTabla = ({estado, tipo, cantidad, procesando}) => {
  const pronombre =
    cantidad < 1             ? tipo === 'subscripcion' ? 'Ninguna' : 'Ningún'
  : cantidad === 1           ? tipo === 'subscripcion' ? 'Una' : 'Un'
  : cantidad.toString()

  const sustantivo =
    tipo === 'comentario'    ? `comentario${cantidad > 1 ? 's' : ''}`
  : tipo === 'contacto'      ? `contacto${cantidad > 1 ? 's' : ''}`
  : tipo === 'asesoramiento' ? `pedido${cantidad > 1 ? 's' : ''} de asesoramiento`
  : tipo === 'subscripcion'  ? `subscripci${cantidad > 1 ? 'ones' : 'ón'}`
  : `mensaje${cantidad > 1 ? 's' : ''}`

  const adjetivo =
    estado === 'pendiente'   ? `pendiente${cantidad > 1 ? 's' : ''}`
  : estado === 'publicado'   ? `publicad${tipo === 'subscripcion' ? 'a' : 'o'}${ cantidad > 1 ? 's' : '' }`
  : estado === 'spam'        ? `spam${cantidad > 1 ? 's' : ''}`
  : estado === 'borrado'     ? `borrad${tipo === 'subscripcion' ? 'a' : 'o'}${cantidad > 1 ? 's' : ''}`
  : estado === 'rechazado'   ? `rechazad${tipo === 'subscripcion' ? 'a' : 'o'}${ cantidad > 1 ? 's' : '' }`
  : ''

  const texto =
    procesando               ? 'El procesador de información se halla procesando la información...'
  : !estado                  ? 'Falta especificar el estado de los mensajes a revisar'
  : !tipo                    ? 'Falta especificar el tipo de los mensajes a revisar'
  : `${pronombre} ${sustantivo} ${adjetivo}`

  return <div css={csstitulotabla}>{texto}</div>
}

const SelectoresDeTipo = ({tipoActual, handleChangeTipo}) => (
  <ToggleButtonGroup
    exclusive
    orientation = "vertical"
    value       = {tipoActual}
    onChange    = {handleChangeTipo}
    css         = {csstipos}
  >
    {tipos.map((tipo, indice) => (
      <ToggleButton
        size        = "small"
        value       = {tipo.clave}
        aria-label  = {tipo.clave}
        key         = {indice}
      >
        <Tipo>{`${tipo.plural}`}</Tipo>
      </ToggleButton>
    ))}
  </ToggleButtonGroup>
)
const SelectoresDeAccion = ({
  accionActual,
  handleChangeAccion,
  seleccionados,
  disabled,
}) => (
  <ToggleButtonGroup
    exclusive
    orientation ="vertical"
    value       ={accionActual}
    onChange    ={handleChangeAccion}
    css         ={cssacciones}
  >
    {tail(estados)
        .map(x => x.clave)
        .map((accion, indice) => (
          <ToggleButton
            value       = {accion}
            size        = "small"
            aria-label  = {accion}
            key         = {indice}
            disabled    = {disabled || seleccionados.length === 0}
          >
            <Accion>{`a ${accion}s`}</Accion>
          </ToggleButton>
        ))}
  </ToggleButtonGroup>
)

const csstituloopciones = css`
  text-align: center;
  font-size: 12px;
  background-color: #16455a;
  color: white;
  padding: 4px;
`
const TituloDeOpciones = ({children}) => {
  return <div css={csstituloopciones}> {children} </div>
}

const TituloDeAcciones = ({seleccionados}) => {
  const textoTitulo =
      seleccionados.length === 0 ? 'No tenés mensajes seleccionados'
    : seleccionados.length === 1 ? 'Tenés un mensaje seleccionado. Podés pasarlo...'
    : `Tenés ${seleccionados.length} mensajes seleccionados. Podés pasarlos...`
  return <TituloDeOpciones>{textoTitulo}</TituloDeOpciones>
}

const TituloDeTipos = () => (
  <TituloDeOpciones>
    <div>
      Seleccionás por <br />
      tipo...
    </div>
  </TituloDeOpciones>
)

const TituloDeEstados = () => (
  <TituloDeOpciones>
    <div>
      ...y por <br />
      estado.
    </div>
  </TituloDeOpciones>
)
const TituloDeCierre = ({modificaciones}) => {
  const textoTitulo =
      modificaciones.length === 0 ? 'No tenés modificaciones preparadas'
    : modificaciones.length === 1 ? 'Tenés una modificación preparada'
    : `Tenés ${modificaciones.length} modificaciones preparadas`

  return  <div>
    <TituloDeOpciones>{textoTitulo}</TituloDeOpciones>
  </div>
}

const eliminarModificacionPorTipoYId = (tipo, id) => filter(y => y.tipo !== tipo || y.id !== id)

const agregarElementoDadoAUnArray = x => flip(concat)([x])

const actualizacionDeModificaciones = modificacion =>
  pipe(
      eliminarModificacionPorTipoYId(modificacion.tipo, modificacion.id),
      agregarElementoDadoAUnArray(modificacion),
  )

const cssboton = css`
  width: 100%;
`
const CheckOut = ({
  modificaciones,
  setModificaciones,
  setSeleccionados,
  limpiarModificaciones,
  setProcesando,
  estadoActual,
  tipoActual,
  setMensajes,
}) => {
  const ejecutarModificaciones = async () => {
    setProcesando(true)
    await efectuarModificaciones(modificaciones)
    setModificaciones([])
    setSeleccionados([])
    setProcesando(false)
    cargarLista(tipoActual, estadoActual, setMensajes, setProcesando)
  }
  return (
    <div>
      <TituloDeCierre modificaciones={modificaciones}></TituloDeCierre>
      <EnvoltorioDeBotonSolitario>
        <Button
          onClick   = {ejecutarModificaciones}
          css       = {cssboton}
          variant   = "contained"
          color     = "secondary"
          disabled  = {modificaciones.length === 0}
        >
          Ejecutarlas
        </Button>
      </EnvoltorioDeBotonSolitario>
      <EnvoltorioDeBotonSolitario>
        <Button onClick={limpiarModificaciones} css={cssboton}>
          Descartarlas
        </Button>
      </EnvoltorioDeBotonSolitario>
    </div>
  )
}
const cargarLista = async ( tipoActual, estadoActual, setMensajes, setProcesando ) => {
  setProcesando(true)
  await conseguirMensajesPorTipoYEstado(tipoActual, estadoActual).then( lista => {
    setMensajes(lista.records)
    setProcesando(false)
  })
}
const Imprimatur = () => {
  const [estadoActual,   setEstadoActual]   = React.useState('pendiente')
  const [tipoActual,     setTipoActual]     = React.useState('todo')
  const [accionActual,   setAccionActual]   = React.useState('nada')
  const [mensajes,       setMensajes]       = React.useState([])
  const [seleccionados,  setSeleccionados]  = React.useState([])
  const [modificaciones, setModificaciones] = React.useState([])
  const [procesando,     setProcesando]     = React.useState(false)

  React.useEffect(() => {
    cargarLista(tipoActual, estadoActual, setMensajes, setProcesando)
  }, [tipoActual, estadoActual, setMensajes])

  const limpiarModificaciones = () => setModificaciones([])

  const handleChangeTipo = (_, proximo) => {
    if (proximo !== null) {
      setProcesando(true)
      setTipoActual(proximo)
      cargarLista(proximo, estadoActual, setMensajes, setProcesando)
    }
  }

  const handleChangeEstado = (_, proximo) => {
    if (proximo !== null) {
      setProcesando(true)
      setEstadoActual(proximo)
      cargarLista(tipoActual, proximo, setMensajes, setProcesando)
    }
  }

  const handleChangeAccion = (_, proximo) => {
    const efectivo = proximo ? proximo : accionActual
    if (proximo !== null) {
      setAccionActual(proximo)
    }
    seleccionados.forEach(id =>
      incorporarModificacion({
        tipo: CAMBIO_DE_ESTADO_DEL_MENSAJE,
        id,
        estado: efectivo,
      }),
    )
    setSeleccionados([])
  }

  const gataFlora = (xs, x) => xs.includes(x) ? xs.filter(y => y !== x) : xs.concat([x])

  const alternarSeleccion = x => setSeleccionados(gataFlora(seleccionados, x))

  const incorporarModificacion = modificacion => {
    if (modificacion.estado)  setModificaciones(actualizacionDeModificaciones(modificacion))
    else                      limpiarModificaciones()
  }

  const limpiarSeleccionados = () => setSeleccionados([])

  return (
    <Blanquita>
      <EnvoltorioDeLaTabla>
        <SidebarIzquierdo>
          <TituloDeTipos />
          <SelectoresDeTipo
            tipoActual={tipoActual}
            handleChangeTipo={handleChangeTipo}
          />
          <EspaciadorVertical altura={40} />
          <TituloDeEstados />
          <SelectoresDeEstado
            estadoActual={estadoActual}
            handleChangeEstado={handleChangeEstado}
          />
        </SidebarIzquierdo>
        <LaTabla>
          <TituloDeTabla
            cantidad={mensajes.length}
            estado={estadoActual}
            tipo={tipoActual}
            procesando={procesando}
          />
          {mensajes.map((mensaje, indice) => (
            <Mensaje
              mensaje={mensaje}
              seleccionado={seleccionados.includes(mensaje.id)}
              key={indice}
              handleClick={() => alternarSeleccion(mensaje.id)}
            />
          ))}
        </LaTabla>
        <SidebarDerecho>
          <SelectoresDeAccion
            accionActual       = {accionActual}
            handleChangeAccion = {handleChangeAccion}
            seleccionados      = {seleccionados}
          />
          <EnvoltorioDeBotonSolitario>
            <Button onClick={limpiarSeleccionados} css={cssboton}>
              Limpiar selección
            </Button>
          </EnvoltorioDeBotonSolitario>
          <EspaciadorVertical altura={40} />
          <CheckOut
            modificaciones        = {modificaciones}
            setModificaciones     = {setModificaciones}
            setSeleccionados      = {setSeleccionados}
            limpiarModificaciones = {limpiarModificaciones}
            setProcesando         = {setProcesando}
            estadoActual          = {estadoActual}
            tipoActual            = {tipoActual}
            setMensajes           = {setMensajes}
          />
        </SidebarDerecho>
      </EnvoltorioDeLaTabla>
    </Blanquita>
  )
}

export default Imprimatur
