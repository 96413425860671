export const tipos = [{
  clave: 'todo',
  nombre: 'Todo tipo',
  singular: 'un',
  plural: 'todos',
  ausencia: 'ningún',
}, {
  clave: 'comentario',
  nombre: 'comentario',
  singular: 'comentario',
  plural: 'comentarios',
  ausencia: 'ningún',
  unidad: 'un',
}, {
  clave: 'asesoramiento',
  nombre: 'solicitud de asesoramiento',
  singular: 'solicitud de asesoramiento',
  plural: 'solicitudes de asesoramiento',
  ausencia: 'ninguna',
  unidad: 'una',
}, {
  clave: 'contacto',
  nombre: 'contacto',
  singular: 'contacto',
  plural: 'contactos',
  ausencia: 'ningún',
  unidad: 'un',
}, {
  clave: 'subscripcion',
  nombre: 'subscripción',
  singular: 'subscripción',
  plural: 'subscripciones',
  ausencia: 'ninguna',
  unidad: 'una',
}]
export const estados = [{
  clave: 'todo',
  nombre: 'cualquier estado',
}, {
  clave: 'pendiente',
  nombre: 'pendiente',
}, {
  clave: 'publicado',
  nombre: 'publicado',
}, {
  clave: 'spam',
  nombre: 'spam',
}, {
  clave: 'borrado',
  nombre: 'borrado',
}, {
  clave: 'rechazado',
  nombre: 'rechazado',
},
]

export const CAMBIO_DE_ESTADO_DEL_MENSAJE = 'CAMBIO_DE_ESTADO_DEL_MENSAJE'
